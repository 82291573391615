import {AbstractControl, ValidationErrors} from '@angular/forms';

export const NOT_END_WITH_VALIDATOR_KEY = 'not_end_with';

export const notEndWithValidator =
  (value: string) =>
  (control: AbstractControl): ValidationErrors | null => {
    const invalid = control.value && control.value[control.value.length - 1] === value;

    return invalid ? {[NOT_END_WITH_VALIDATOR_KEY]: {value: control.value}} : null;
  };
